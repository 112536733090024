/*
----------------------------------------
USWDS with settings overrides
----------------------------------------
Uncomment the following lines and add a list of changed settings
in the form $setting: value,
----------------------------------------
*/
/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
GENERAL SETTINGS
----------------------------------------
Read more about settings and
USWDS style tokens in the documentation:
https://designsystem.digital.gov/design-tokens
----------------------------------------
*/
/*
----------------------------------------
Image path
----------------------------------------
Relative image file path
----------------------------------------
*/
/*
----------------------------------------
Show compile warnings
----------------------------------------
Show Sass warnings when functions and
mixins use non-standard tokens.
AND
Show updates and notifications.
----------------------------------------
*/
/*
----------------------------------------
Namespace
----------------------------------------
*/
/*
----------------------------------------
Prefix separator
----------------------------------------
Set the character the separates
responsive and state prefixes from the
main class name.
The default (":") needs to be preceded
by two backslashes to be properly
escaped.
----------------------------------------
*/
/*
----------------------------------------
Layout grid
----------------------------------------
Should the layout grid classes output
with !important
----------------------------------------
*/
/*
----------------------------------------
Border box sizing
----------------------------------------
When set to true, sets the box-sizing
property of all site elements to
`border-box`.
----------------------------------------
*/
/*
----------------------------------------
Focus styles
----------------------------------------
*/
/*
----------------------------------------
Icons
----------------------------------------
*/
/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
TYPOGRAPHY SETTINGS
----------------------------------------
Read more about settings and
USWDS typography tokens in the documentation:
https://designsystem.digital.gov/design-tokens/typesetting/overview/
----------------------------------------
*/
/*
----------------------------------------
Root font size
----------------------------------------
Setting $theme-respect-user-font-size to
true sets the root font size to 100% and
uses ems for media queries
----------------------------------------
$theme-root-font-size only applies when
$theme-respect-user-font-size is set to
false.

This will set the root font size
as a specific px value and use px values
for media queries.

Accepts true or false
----------------------------------------
*/
/*
----------------------------------------
Global styles
----------------------------------------
Adds basic styling for the following
unclassed elements:

- paragraph: paragraph text
- link: links
- content: paragraph text, links,
  headings, lists, and tables
----------------------------------------
*/
/*
----------------------------------------
Broswer compatibility mode
----------------------------------------
When true, outputs woff and ttf font 
formats in addition to woff2
----------------------------------------
*/
/*
----------------------------------------
Font path
----------------------------------------
Relative font file path
----------------------------------------
*/
/*
----------------------------------------
Custom typeface tokens
----------------------------------------
Add a new custom typeface token if
your project uses a typeface not already
defined by USWDS.
----------------------------------------
USWDS defines the following tokens
by default:
----------------------------------------
'georgia'
'helvetica'
'merriweather'
'open-sans'
'public-sans'
'roboto-mono'
'source-sans-pro'
'system'
'tahoma'
'verdana'
----------------------------------------
Add as many new tokens as you have
custom typefaces. Reference your new
token(s) in the type-based font settings
using the quoted name of the token.

For example:

$theme-font-type-cond: 'example-font-token';

display-name:
The display name of your font

cap-height:
The height of a 500px `N` in Sketch
----------------------------------------
You should change `example-[style]-token`
names to something more descriptive.
----------------------------------------
*/
/*
----------------------------------------
Type-based font settings
----------------------------------------
Set the type-based tokens for your
project from the following tokens,
or from any new font tokens you added in
$theme-typeface-tokens.
----------------------------------------
'georgia'
'helvetica'
'merriweather'
'open-sans'
'public-sans'
'roboto-mono'
'source-sans-pro'
'system'
'tahoma'
'verdana'
----------------------------------------
*/
/*
----------------------------------------
Custom font stacks
----------------------------------------
Add custom font stacks to any of the
type-based fonts. Any USWDS typeface
token already has a default stack.

Custom stacks don't need to include the
font's display name. It will
automatically appear at the start of
the stack.
----------------------------------------
Example:
$theme-font-type-sans: 'source-sans-pro';
$theme-font-sans-custom-stack: "Helvetica Neue", Helvetica, Arial, sans;

Output:
font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans;
----------------------------------------
*/
/*
----------------------------------------
Add any custom font source files
----------------------------------------
If you want USWDS to generate additional
@font-face declarations, add your font
data below, following the example that
follows.
----------------------------------------
USWDS automatically generates @font-face
declarations for the following

'merriweather'
'public-sans'
'roboto-mono'
'source-sans-pro'

These typefaces not require custom
source files.
----------------------------------------
EXAMPLE

- dir:
  Directory relative to $theme-font-path
- This directory should include fonts saved as
  .woff2
  ExampleSerif-Normal.woff2

$theme-font-serif-custom-src: (
  dir: 'custom/example-serif',
  roman: (
    100: false,
    200: false,
    300: 'ExampleSerif-Light',
    400: 'ExampleSerif-Normal',
    500: false,
    600: false,
    700: 'ExampleSerif-Bold',
    800: false,
    900: false,
  ),
  italic: (
    100: false,
    200: false,
    300: 'ExampleSerif-LightItalic',
    400: 'ExampleSerif-Italic',
    500: false,
    600: false,
    700: 'ExampleSerif-BoldItalic',
    800: false,
    900: false,
  ),
);
----------------------------------------
*/
/*
----------------------------------------
Role-based font settings
----------------------------------------
Set the role-based tokens for your
project from the following font-type
tokens.
----------------------------------------
'cond'
'icon'
'lang'
'mono'
'sans'
'serif'
----------------------------------------
*/
/*
----------------------------------------
Type scale
----------------------------------------
Define your project's type scale using
values from the USWDS system type scale

1-20
----------------------------------------
*/
/*
----------------------------------------
Font weights
----------------------------------------
Assign weights 100-900
Or use `false` for unneeded weights.
----------------------------------------
*/
/*
----------------------------------------
General typography settings
----------------------------------------
Type scale tokens
----------------------------------------
micro:      10px
1:          12px
2:          13px
3:          14px
4:          15px
5:          16px
6:          17px
7:          18px
8:          20px
9:          22px
10:         24px
11:         28px
12:         32px
13:         36px
14:         40px
15:         48px
16:         56px
17:         64px
18:         80px
19:         120px
20:         140px
----------------------------------------
Line height tokens
----------------------------------------
1:    1
2:    1.15
3:    1.35
4:    1.5
5:    1.62
6:    1.75
----------------------------------------
Font role tokens
----------------------------------------
'ui'
'heading'
'body'
'code'
'alt'
----------------------------------------
Measure (max-width) tokens
----------------------------------------
1:       44ex
2:       60ex
3:       64ex
4:       68ex
5:       74ex
6:       88ex
none:    none
----------------------------------------
*/
/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
COLOR SETTINGS
----------------------------------------
Read more about settings and
USWDS color tokens in the documentation:
https://designsystem.digital.gov/design-tokens/color
----------------------------------------
*/
/*
----------------------------------------
Theme palette colors
----------------------------------------
*/
/*
----------------------------------------
State palette colors
----------------------------------------
*/
/*
----------------------------------------
General colors
----------------------------------------
*/
/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
COMPONENT SETTINGS
----------------------------------------
Read more about settings and
USWDS style tokens in the documentation:
https://designsystem.digital.gov/design-tokens
----------------------------------------
*/
/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
SPACING SETTINGS
----------------------------------------
Read more about settings and
USWDS spacing units tokens in the
documentation:
https://designsystem.digital.gov/design-tokens/spacing-units
----------------------------------------
*/
/*
----------------------------------------
Border radius
----------------------------------------
2px           2px
0.5           4px
1             8px
1.5           12px
2             16px
2.5           20px
3             24px
4             32px
5             40px
6             48px
7             56px
8             64px
9             72px
----------------------------------------
*/
/*
----------------------------------------
Column gap
----------------------------------------
2px         2px
0.5         4px
1           8px
2           16px
3           24px
4           32px
5           40px
6           48px
----------------------------------------
*/
/*
----------------------------------------
Grid container max-width
----------------------------------------
mobile
mobile-lg
tablet
tablet-lg
desktop
desktop-lg
widescreen
----------------------------------------
*/
/*
----------------------------------------
Site
----------------------------------------
*/
/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
UTILITIES SETTINGS
----------------------------------------
Read more about settings and
USWDS utilities in the documentation:
https://designsystem.digital.gov/utilities
----------------------------------------
*/
/*
----------------------------------------
Utility breakpoints
----------------------------------------
Which breakpoints does your project
need? Select as `true` any breakpoint
used by utilities or layout grid
----------------------------------------
*/
/*
----------------------------------------
Global colors
----------------------------------------
The following palettes will be added to
- background-color
- border-color
- color
- text-decoration-color
----------------------------------------
*/
/*
----------------------------------------
Settings
----------------------------------------
*/
/*
----------------------------------------
Values
----------------------------------------
*/
/*
----------------------------------------
get-last()
----------------------------------------
Return the last item of a list,
Return null if the value is null
----------------------------------------
*/
/*
----------------------------------------
append-important()
----------------------------------------
Append `!important` to a list
----------------------------------------
*/
/*
----------------------------------------
de-list()
----------------------------------------
Transform a one-element list or arglist
into that single element.
----------------------------------------
(1) => 1
((1)) => (1)
----------------------------------------
*/
/*
----------------------------------------
uswds-error()
----------------------------------------
Allow the system to pass an error as text
to test error states in unit testing
----------------------------------------
*/
/*
----------------------------------------
error-not-token()
----------------------------------------
Returns a common not-a-token error.
----------------------------------------
*/
/*
----------------------------------------
get-default()
----------------------------------------
Returns the default value from a map
of project defaults
get-default("bg-color")
> $theme-body-background-color
----------------------------------------
*/
/*
----------------------------------------
has-important()
----------------------------------------
Check to see if `!important` is
being passed in a mixin's props
----------------------------------------
*/
/*
----------------------------------------
map-collect()
----------------------------------------
Collect multiple maps into a single
large map
source: https://gist.github.com/bigglesrocks/d75091700f8f2be5abfe
----------------------------------------
*/
/*
----------------------------------------
map-deep-get()
----------------------------------------
@author Hugo Giraudel
@access public
@param {Map} $map - Map
@param {Arglist} $keys - Key chain
@return {*} - Desired value
----------------------------------------
*/
/*
----------------------------------------
multi-cat()
----------------------------------------
Concatenate two lists
----------------------------------------
*/
/*
----------------------------------------
remove()
----------------------------------------
Remove a value from a list
----------------------------------------
*/
/*
----------------------------------------
smart-quote()
----------------------------------------
Quotes strings
Inspects `px`, `xs`, and `xl` numbers
Leaves bools as is
----------------------------------------
*/
/*
----------------------------------------
str-replace()
----------------------------------------
Replace any substring with another
string
----------------------------------------
*/
/*
----------------------------------------
str-split()
----------------------------------------
Split a string at a given separator
and convert into a list of substrings
----------------------------------------
*/
/*
----------------------------------------
strip-unit()
----------------------------------------
Remove the unit of a length
@author Hugo Giraudel
@param {Number} $number - Number to remove unit from
@return {Number} - Unitless number
----------------------------------------
*/
/*
----------------------------------------
base-to-map()
@TODO: Deprecate and delete
----------------------------------------
Convert a single base to a USWDS
value map.

Candidate for deprecation if we remove
isReadable
----------------------------------------
*/
/*
----------------------------------------
to-number()
----------------------------------------
Casts a string into a number
----------------------------------------
@param {String | Number} $value - Value to be parsed
@return {Number}
----------------------------------------
*/
/*
----------------------------------------
unpack()
----------------------------------------
Create lists of single items from lists
of lists.
----------------------------------------
(1, (2.1, 2.2), 3) -->
(1, 2.1, 2.2, 3)
----------------------------------------
*/
/*
----------------------------------------
get-system-color()
----------------------------------------
Derive a system color from its
family, value, and vivid or a passed
variable that is, itself, a list
----------------------------------------
*/
/*
----------------------------------------
set-theme-color()
----------------------------------------
Derive a color from a system color token
or a hex value
----------------------------------------
*/
/*
----------------------------------------
color()
----------------------------------------
Derive a color from a color shortcode
----------------------------------------
*/
/*
----------------------------------------
px-to-rem()
----------------------------------------
Converts a value in px to a value in rem
----------------------------------------
*/
/*
----------------------------------------
rem-to-px()
----------------------------------------
Converts a value in rem to a value in px
----------------------------------------
*/
/*
----------------------------------------
rem-to-user-em()
----------------------------------------
Converts a value in rem to a value in
[user-settings] em for use in media
queries
----------------------------------------
*/
/*
----------------------------------------
spacing-multiple()
----------------------------------------
Converts a spacing unit multiple into
the desired final units (currently rem)
----------------------------------------
*/
/*
----------------------------------------
number-to-token()
----------------------------------------
Converts an integer or numeric value
into a system value

Ex: 0.5   --> '05'
    -1px  --> 'neg-1px'
----------------------------------------
*/
/*
----------------------------------------
units()
----------------------------------------
Converts a spacing unit into
the desired final units (currently rem)
----------------------------------------
*/
/*
----------------------------------------
Project fonts
----------------------------------------
Collects font settings in a map for
looping.
----------------------------------------
*/
/*
----------------------------------------
Luminance ranges
----------------------------------------
*/
/*
----------------------------------------
ns()
----------------------------------------
Add a namesspace of $type if that
namespace is set to output
----------------------------------------
*/
/*
----------------------------------------
Line height
----------------------------------------
*/
/*
----------------------------------------
Measure
----------------------------------------
*/
/*
----------------------------------------
validate-typeface-token()
----------------------------------------
Check to see if a typeface-token exists.
Throw an error if a passed token does
not exist in the typeface-token map.
----------------------------------------
*/
/*
----------------------------------------
cap-height()
----------------------------------------
Get the cap height of a valid typeface
----------------------------------------
*/
/*
----------------------------------------
convert-to-font-type()
----------------------------------------
Converts a font-role token into a
font-type token. Leaves font-type tokens
unchanged.
----------------------------------------
*/
/*
----------------------------------------
font-sources()
----------------------------------------
Outputs a list of font sources used in
a @font-face declaration.

$theme-font-browser-compatibility: true - output woff2, woff, ttf
$theme-font-browser-compatibility: false - output woff2

@param stem: string - [font path]/[custom-src.dir]/[custom-src.[style].[weight]]
@output: string

filetypes must be one of the filetypes set in variables/$project-font-face-filetypes (woff, woff2, ttf)
----------------------------------------
*/
/*
----------------------------------------
get-font-stack()
----------------------------------------
Get a font stack from a style- or
role-based font token.
----------------------------------------
*/
/*
----------------------------------------
get-typeface-token()
----------------------------------------
Get a typeface token from a font-type or
font-role token.
----------------------------------------
*/
/*
----------------------------------------
normalize-type-scale()
----------------------------------------
Normalizes a specific face's optical size
to a set target
----------------------------------------
*/
/*
----------------------------------------
system-type-scale()
----------------------------------------
Get a value from the system type scale
----------------------------------------
*/
/*
----------------------------------------
Easing
----------------------------------------
*/
/*  deprecated.scss
    ---
    Occasionally the design system will deprecate
    old variables or functionality. If we replace
    the old functionality with something new, this is a
    place to connect the old functionality to the
    new functionality, in the service of better
    continuity and backwards compatibility within a
    major release cycle.

    Note the USWDS version where we deprecated the
    old functionality in a comment.

    Be sure to update notifications.scss.

    This file should started fresh at each
    major version.
*/
/*
----------------------------------------
advanced-color()
----------------------------------------
Derive a color from a color triplet:
[family], [grade], [variant]
----------------------------------------
*/
/*
----------------------------------------
is-system-color-token()
----------------------------------------
Return whether a token is a system
color token
----------------------------------------
*/
/*
----------------------------------------
is-theme-color-token()
----------------------------------------
Return whether a token is a theme
color token
----------------------------------------
*/
/*
----------------------------------------
color-token-assignment()
----------------------------------------
Get the system token equivalent of any
theme color token
----------------------------------------
*/
/*
----------------------------------------
decompose()
----------------------------------------
Convert a color token into into a list
of form [family], [grade], [variant]
Vivid variants return "vivid" as the
variant.
If neither grade nor variant exists,
returns 'null'
----------------------------------------
*/
/*
----------------------------------------
color-token-family()
----------------------------------------
Returns the family of a color token.
Returns: color-family
color-token-family("accent-warm-vivid")
> "accent-warm"
color-token-family("red-50v")
> "red"
color-token-variant(("red", 50, "vivid"))
> "red"
----------------------------------------
*/
/*
----------------------------------------
color-token-grade()
----------------------------------------
Returns the grade of a USWDS color token.
Returns: color-grade
color-token-grade("accent-warm")
> "root"
color-token-grade("accent-warm-vivid")
> "root"
color-token-grade("accent-warm-darker")
> "darker"
color-token-grade("red-50v")
> 50
color-token-variant(("red", 50, "vivid"))
> 50
----------------------------------------
*/
/*
----------------------------------------
is-color-token()
----------------------------------------
Returns whether a given string is a
USWDS color token.
----------------------------------------
*/
/*
----------------------------------------
pow()
----------------------------------------
Raises a unitless number to the power
of another unitless number
Includes helper functions
----------------------------------------
*/
/*
----------------------------------------
Helper functions
----------------------------------------
*/
/* factorial()
----------------------------------------
*/
/* summation()
----------------------------------------
*/
/* exp-maclaurin()
----------------------------------------
*/
/* ln()
----------------------------------------
*/
/*
----------------------------------------
calculate-grade()
----------------------------------------
Derive the grade equivalent any color,
even non-token colors
----------------------------------------
*/
/*
----------------------------------------
color-token-type()
----------------------------------------
Returns the type of a color token.
Returns: "system" | "theme"
----------------------------------------
*/
/*
----------------------------------------
color-token-variant()
----------------------------------------
Returns the variant of color token.
Returns: "vivid" | false
color-token-variant("accent-warm")
> false
color-token-variant("accent-warm-vivid")
> "vivid"
color-token-variant("red-50v")
> "vivid"
color-token-variant(("red", 50, "vivid"))
> "vivid"
----------------------------------------
*/
/*
----------------------------------------
magic-number()
----------------------------------------
Returns the magic number of two color
grades. Takes numbers or color tokens.
magic-number(50, 10)
return: 40
magic-number("red-50", "red-10")
return: 40
----------------------------------------
*/
/*
----------------------------------------
wcag-magic-number()
----------------------------------------
Returns the magic number of a specific
wcag grade:
"AA"
"AA-Large"
"AAA"
wcag-magic-number("AA")
> 50
----------------------------------------
*/
/*
----------------------------------------
is-accessible-magic-number()
----------------------------------------
Returns whether two grades achieve
specified target color contrast
Returns: true | false
is-accessible-magic-number(10, 50, "AA")
> false
is-accessible-magic-number(10, 60, "AA")
> true
----------------------------------------
*/
/*
----------------------------------------
next-token()
----------------------------------------
Returns next "darker" or "lighter" color
token of the same token type and variant.
Returns: color-token | false
next-token("accent-warm", "lighter")
> "accent-warm-light"
next-token("gray-10", "lighter")
> "gray-5"
next-token("gray-5", "lighter")
> "white"
next-token("white", "lighter")
> false
next-token("red-50v", "darker")
> "red-60v"
next-token("red-50", "darker")
> "red-60"
next-token("red-80v", "darker")
> "red-90"
next-token("red-90", "darker")
> "black"
next-token("white", "darker")
> "gray-5"
next-token("black", "lighter")
> "gray-90"
----------------------------------------
*/
/*
----------------------------------------
get-link-tokens-from-bg()
----------------------------------------
Get accessible link colors for a given
background color
returns: link-token, hover-token
get-link-tokens-from-bg(
  "black",
  "red-60",
  "red-10",
  "AA")
> "red-10", "red-5"
get-link-tokens-from-bg(
  "black",
  "red-60v",
  "red-10v",
  "AA-large")
> "red-60v", "red-50v"
get-link-tokens-from-bg(
  "black",
  "red-5v",
  "red-60v",
  "AA")
> "red-5v", "white"
get-link-tokens-from-bg(
  "black",
  "white",
  "red-60v",
  "AA")
> "white", "white"
----------------------------------------
*/
/*
----------------------------------------
test-colors()
----------------------------------------
Check to see if all system colors
fall between the proper relative
luminance range for their grade.
Has a couple quirks, as the luminance()
function returns slightly different
results than expected.
----------------------------------------
*/
/*
----------------------------------------
columns()
----------------------------------------
outputs a grid-col number based on
the number of desired columns in the
12-column grid

Ex: columns(2) --> 6
    grid-col(columns(2))
----------------------------------------
*/
/*
----------------------------------------
USWDS Properties
----------------------------------------
*/
/*
----------------------------------------
get-uswds-value()
----------------------------------------
Finds and outputs a value from the
USWDS standard values.

Used to build other standard utility
functions and mixins.
----------------------------------------
*/
/*
----------------------------------------
get-standard-values()
----------------------------------------
Gets a map of USWDS standard values
for a property
----------------------------------------
*/
/*
----------------------------------------
border-radius()
----------------------------------------
Get a border-radius from the system
border-radii
----------------------------------------
*/
/*
----------------------------------------
font-weight()
fw()
----------------------------------------
Get a font-weight value from the
system font-weight
----------------------------------------
*/
/*
----------------------------------------
feature()
----------------------------------------
Gets a valid USWDS font feature setting
----------------------------------------
*/
/*
----------------------------------------
flex()
----------------------------------------
Gets a valid USWDS flex value
----------------------------------------
*/
/*
----------------------------------------
font-family()
family()
----------------------------------------
Get a font-family stack from a
role-based or type-based font family
----------------------------------------
*/
/*
----------------------------------------
letter-spacing()
ls()
----------------------------------------
Get a letter-spacing value from the
system letter-spacing
----------------------------------------
*/
/*
----------------------------------------
measure()
----------------------------------------
Gets a valid USWDS reading line length
----------------------------------------
*/
/*
----------------------------------------
opacity()
----------------------------------------
Get an opacity from the system
opacities
----------------------------------------
*/
/*
----------------------------------------
order()
----------------------------------------
Get an order value from the
system orders
----------------------------------------
*/
/*
----------------------------------------
radius()
----------------------------------------
Get a border-radius value from the
system letter-spacing
----------------------------------------
*/
/*
----------------------------------------
font-size()
----------------------------------------
Get type scale value from a [family] and
[scale]
----------------------------------------
*/
/*
----------------------------------------
z-index()
z()
----------------------------------------
Get a z-index value from the
system z-index
----------------------------------------
*/
/*
----------------------------------------
utility-font()
----------------------------------------
Get a normalized font-size in rem from
a family and a type size in either
system scale or project scale
----------------------------------------
Not the public-facing function.
Used for building the utilities and
withholds certain errors.
----------------------------------------
*/
/*
----------------------------------------
family()
----------------------------------------
Get a font-family stack
----------------------------------------
*/
/*
----------------------------------------
size()
----------------------------------------
Get a normalized font-size in rem from
a family and a type size in either
system scale or project scale
----------------------------------------
*/
/*
----------------------------------------
font()
----------------------------------------
Get a font-family stack
AND
Get a normalized font-size in rem from
a family and a type size in either
system scale or project scale
----------------------------------------
*/
/*
----------------------------------------
typeset()
----------------------------------------
Sets:
- family
- size
- line-height
----------------------------------------
*/
/* stylelint-disable max-nesting-depth */
/*
----------------------------------------
@render-pseudoclass
----------------------------------------
Build a pseucoclass utiliy from values
calculated in the @render-utilities-in
loop
----------------------------------------
*/
/*
----------------------------------------
@render-utility
----------------------------------------
Build a utility from values calculated
in the @render-utilities-in loop
----------------------------------------
TODO: Determine the proper use of
unquote() in the following. Changed to
account for a 'interpolation near
operators will be simplified in a
future version of Sass' warning.
----------------------------------------
*/
/*
----------------------------------------
@render-utilities-in
----------------------------------------
The master loop that sets the building
blocks of utilities from the values
in individual rule settings and loops
through all possible variants
----------------------------------------
*/
/* stylelint-enable */
/*  notifications.scss
    ---
    Adds a notification at the top of each USWDS
    compile. Use this file for important notifications
    and updates to the design system.

    This file should started fresh at each
    major version.

*/
/* prettier-ignore */
/* prettier-ignore */
/* stylelint-disable */
@font-face {
  font-family: "Roboto Mono Web";
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
  src: url(../fonts/roboto-mono/roboto-mono-v5-latin-300.woff2) format("woff2");
}
@font-face {
  font-family: "Roboto Mono Web";
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url(../fonts/roboto-mono/roboto-mono-v5-latin-regular.woff2) format("woff2");
}
@font-face {
  font-family: "Roboto Mono Web";
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: url(../fonts/roboto-mono/roboto-mono-v5-latin-700.woff2) format("woff2");
}
@font-face {
  font-family: "Roboto Mono Web";
  font-style: italic;
  font-weight: 300;
  font-display: fallback;
  src: url(../fonts/roboto-mono/roboto-mono-v5-latin-300italic.woff2) format("woff2");
}
@font-face {
  font-family: "Roboto Mono Web";
  font-style: italic;
  font-weight: 400;
  font-display: fallback;
  src: url(../fonts/roboto-mono/roboto-mono-v5-latin-italic.woff2) format("woff2");
}
@font-face {
  font-family: "Roboto Mono Web";
  font-style: italic;
  font-weight: 700;
  font-display: fallback;
  src: url(../fonts/roboto-mono/roboto-mono-v5-latin-700italic.woff2) format("woff2");
}
@font-face {
  font-family: "Source Sans Pro Web";
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
  src: url(../fonts/source-sans-pro/sourcesanspro-light-webfont.woff2) format("woff2");
}
@font-face {
  font-family: "Source Sans Pro Web";
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url(../fonts/source-sans-pro/sourcesanspro-regular-webfont.woff2) format("woff2");
}
@font-face {
  font-family: "Source Sans Pro Web";
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: url(../fonts/source-sans-pro/sourcesanspro-bold-webfont.woff2) format("woff2");
}
@font-face {
  font-family: "Source Sans Pro Web";
  font-style: italic;
  font-weight: 300;
  font-display: fallback;
  src: url(../fonts/source-sans-pro/sourcesanspro-lightitalic-webfont.woff2) format("woff2");
}
@font-face {
  font-family: "Source Sans Pro Web";
  font-style: italic;
  font-weight: 400;
  font-display: fallback;
  src: url(../fonts/source-sans-pro/sourcesanspro-italic-webfont.woff2) format("woff2");
}
@font-face {
  font-family: "Source Sans Pro Web";
  font-style: italic;
  font-weight: 700;
  font-display: fallback;
  src: url(../fonts/source-sans-pro/sourcesanspro-bolditalic-webfont.woff2) format("woff2");
}
@font-face {
  font-family: "Merriweather Web";
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
  src: url(../fonts/merriweather/Latin-Merriweather-Light.woff2) format("woff2");
}
@font-face {
  font-family: "Merriweather Web";
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url(../fonts/merriweather/Latin-Merriweather-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Merriweather Web";
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: url(../fonts/merriweather/Latin-Merriweather-Bold.woff2) format("woff2");
}
@font-face {
  font-family: "Merriweather Web";
  font-style: italic;
  font-weight: 300;
  font-display: fallback;
  src: url(../fonts/merriweather/Latin-Merriweather-LightItalic.woff2) format("woff2");
}
@font-face {
  font-family: "Merriweather Web";
  font-style: italic;
  font-weight: 400;
  font-display: fallback;
  src: url(../fonts/merriweather/Latin-Merriweather-Italic.woff2) format("woff2");
}
@font-face {
  font-family: "Merriweather Web";
  font-style: italic;
  font-weight: 700;
  font-display: fallback;
  src: url(../fonts/merriweather/Latin-Merriweather-BoldItalic.woff2) format("woff2");
}
/* stylelint-enable */
/* stylelint-disable */
.grid-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 64rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.grid-container-card {
  margin-left: auto;
  margin-right: auto;
  max-width: 10rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .grid-container-card {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.grid-container-card-lg {
  margin-left: auto;
  margin-right: auto;
  max-width: 15rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .grid-container-card-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.grid-container-mobile {
  margin-left: auto;
  margin-right: auto;
  max-width: 20rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .grid-container-mobile {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.grid-container-mobile-lg {
  margin-left: auto;
  margin-right: auto;
  max-width: 30rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .grid-container-mobile-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.grid-container-tablet {
  margin-left: auto;
  margin-right: auto;
  max-width: 40rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .grid-container-tablet {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.grid-container-tablet-lg {
  margin-left: auto;
  margin-right: auto;
  max-width: 55rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .grid-container-tablet-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.grid-container-desktop {
  margin-left: auto;
  margin-right: auto;
  max-width: 64rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .grid-container-desktop {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.grid-container-desktop-lg {
  margin-left: auto;
  margin-right: auto;
  max-width: 75rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .grid-container-desktop-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.grid-container-widescreen {
  margin-left: auto;
  margin-right: auto;
  max-width: 87.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .grid-container-widescreen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media all and (min-width: 30em) {
  .mobile-lg\:grid-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-container-card {
    margin-left: auto;
    margin-right: auto;
    max-width: 10rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:grid-container-card {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-container-card-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 15rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:grid-container-card-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-container-mobile {
    margin-left: auto;
    margin-right: auto;
    max-width: 20rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:grid-container-mobile {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-container-mobile-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 30rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:grid-container-mobile-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-container-tablet {
    margin-left: auto;
    margin-right: auto;
    max-width: 40rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:grid-container-tablet {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-container-tablet-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 55rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:grid-container-tablet-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-container-desktop {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:grid-container-desktop {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-container-desktop-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 75rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:grid-container-desktop-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-container-widescreen {
    margin-left: auto;
    margin-right: auto;
    max-width: 87.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:grid-container-widescreen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-container-card {
    margin-left: auto;
    margin-right: auto;
    max-width: 10rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:grid-container-card {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-container-card-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 15rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:grid-container-card-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-container-mobile {
    margin-left: auto;
    margin-right: auto;
    max-width: 20rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:grid-container-mobile {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-container-mobile-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 30rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:grid-container-mobile-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-container-tablet {
    margin-left: auto;
    margin-right: auto;
    max-width: 40rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:grid-container-tablet {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-container-tablet-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 55rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:grid-container-tablet-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-container-desktop {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:grid-container-desktop {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-container-desktop-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 75rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:grid-container-desktop-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-container-widescreen {
    margin-left: auto;
    margin-right: auto;
    max-width: 87.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:grid-container-widescreen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-container-card {
    margin-left: auto;
    margin-right: auto;
    max-width: 10rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:grid-container-card {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-container-card-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 15rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:grid-container-card-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-container-mobile {
    margin-left: auto;
    margin-right: auto;
    max-width: 20rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:grid-container-mobile {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-container-mobile-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 30rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:grid-container-mobile-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-container-tablet {
    margin-left: auto;
    margin-right: auto;
    max-width: 40rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:grid-container-tablet {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-container-tablet-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 55rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:grid-container-tablet-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-container-desktop {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:grid-container-desktop {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-container-desktop-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 75rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:grid-container-desktop-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-container-widescreen {
    margin-left: auto;
    margin-right: auto;
    max-width: 87.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:grid-container-widescreen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.grid-row {
  display: flex;
  flex-wrap: wrap;
}
.grid-row.grid-gap {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.grid-row.grid-gap > * {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
@media all and (min-width: 64em) {
  .grid-row.grid-gap {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .grid-row.grid-gap > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.grid-row.grid-gap-0 {
  margin-left: 0;
  margin-right: 0;
}
.grid-row.grid-gap-0 > * {
  padding-left: 0;
  padding-right: 0;
}
.grid-row.grid-gap-2px {
  margin-left: -1px;
  margin-right: -1px;
}
.grid-row.grid-gap-2px > * {
  padding-left: 1px;
  padding-right: 1px;
}
.grid-row.grid-gap-05 {
  margin-left: -2px;
  margin-right: -2px;
}
.grid-row.grid-gap-05 > * {
  padding-left: 2px;
  padding-right: 2px;
}
.grid-row.grid-gap-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}
.grid-row.grid-gap-1 > * {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.grid-row.grid-gap-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.grid-row.grid-gap-2 > * {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.grid-row.grid-gap-3 {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}
.grid-row.grid-gap-3 > * {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.grid-row.grid-gap-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}
.grid-row.grid-gap-4 > * {
  padding-left: 1rem;
  padding-right: 1rem;
}
.grid-row.grid-gap-5 {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}
.grid-row.grid-gap-5 > * {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.grid-row.grid-gap-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
.grid-row.grid-gap-6 > * {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.grid-row.grid-gap-sm {
  margin-left: -1px;
  margin-right: -1px;
}
.grid-row.grid-gap-sm > * {
  padding-left: 1px;
  padding-right: 1px;
}
.grid-row.grid-gap-md {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.grid-row.grid-gap-md > * {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.grid-row.grid-gap-lg {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}
.grid-row.grid-gap-lg > * {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
@media all and (min-width: 30em) {
  .grid-row.mobile-lg\:grid-gap-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .grid-row.mobile-lg\:grid-gap-0 > * {
    padding-left: 0;
    padding-right: 0;
  }
  .grid-row.mobile-lg\:grid-gap-2px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .grid-row.mobile-lg\:grid-gap-2px > * {
    padding-left: 1px;
    padding-right: 1px;
  }
  .grid-row.mobile-lg\:grid-gap-05 {
    margin-left: -2px;
    margin-right: -2px;
  }
  .grid-row.mobile-lg\:grid-gap-05 > * {
    padding-left: 2px;
    padding-right: 2px;
  }
  .grid-row.mobile-lg\:grid-gap-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .grid-row.mobile-lg\:grid-gap-1 > * {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .grid-row.mobile-lg\:grid-gap-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .grid-row.mobile-lg\:grid-gap-2 > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .grid-row.mobile-lg\:grid-gap-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .grid-row.mobile-lg\:grid-gap-3 > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .grid-row.mobile-lg\:grid-gap-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .grid-row.mobile-lg\:grid-gap-4 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .grid-row.mobile-lg\:grid-gap-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .grid-row.mobile-lg\:grid-gap-5 > * {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .grid-row.mobile-lg\:grid-gap-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .grid-row.mobile-lg\:grid-gap-6 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .grid-row.mobile-lg\:grid-gap-sm {
    margin-left: -1px;
    margin-right: -1px;
  }
  .grid-row.mobile-lg\:grid-gap-sm > * {
    padding-left: 1px;
    padding-right: 1px;
  }
  .grid-row.mobile-lg\:grid-gap-md {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .grid-row.mobile-lg\:grid-gap-md > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .grid-row.mobile-lg\:grid-gap-lg {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .grid-row.mobile-lg\:grid-gap-lg > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
@media all and (min-width: 40em) {
  .grid-row.tablet\:grid-gap-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .grid-row.tablet\:grid-gap-0 > * {
    padding-left: 0;
    padding-right: 0;
  }
  .grid-row.tablet\:grid-gap-2px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .grid-row.tablet\:grid-gap-2px > * {
    padding-left: 1px;
    padding-right: 1px;
  }
  .grid-row.tablet\:grid-gap-05 {
    margin-left: -2px;
    margin-right: -2px;
  }
  .grid-row.tablet\:grid-gap-05 > * {
    padding-left: 2px;
    padding-right: 2px;
  }
  .grid-row.tablet\:grid-gap-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .grid-row.tablet\:grid-gap-1 > * {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .grid-row.tablet\:grid-gap-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .grid-row.tablet\:grid-gap-2 > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .grid-row.tablet\:grid-gap-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .grid-row.tablet\:grid-gap-3 > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .grid-row.tablet\:grid-gap-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .grid-row.tablet\:grid-gap-4 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .grid-row.tablet\:grid-gap-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .grid-row.tablet\:grid-gap-5 > * {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .grid-row.tablet\:grid-gap-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .grid-row.tablet\:grid-gap-6 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .grid-row.tablet\:grid-gap-sm {
    margin-left: -1px;
    margin-right: -1px;
  }
  .grid-row.tablet\:grid-gap-sm > * {
    padding-left: 1px;
    padding-right: 1px;
  }
  .grid-row.tablet\:grid-gap-md {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .grid-row.tablet\:grid-gap-md > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .grid-row.tablet\:grid-gap-lg {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .grid-row.tablet\:grid-gap-lg > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
@media all and (min-width: 64em) {
  .grid-row.desktop\:grid-gap-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .grid-row.desktop\:grid-gap-0 > * {
    padding-left: 0;
    padding-right: 0;
  }
  .grid-row.desktop\:grid-gap-2px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .grid-row.desktop\:grid-gap-2px > * {
    padding-left: 1px;
    padding-right: 1px;
  }
  .grid-row.desktop\:grid-gap-05 {
    margin-left: -2px;
    margin-right: -2px;
  }
  .grid-row.desktop\:grid-gap-05 > * {
    padding-left: 2px;
    padding-right: 2px;
  }
  .grid-row.desktop\:grid-gap-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .grid-row.desktop\:grid-gap-1 > * {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .grid-row.desktop\:grid-gap-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .grid-row.desktop\:grid-gap-2 > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .grid-row.desktop\:grid-gap-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .grid-row.desktop\:grid-gap-3 > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .grid-row.desktop\:grid-gap-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .grid-row.desktop\:grid-gap-4 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .grid-row.desktop\:grid-gap-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .grid-row.desktop\:grid-gap-5 > * {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .grid-row.desktop\:grid-gap-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .grid-row.desktop\:grid-gap-6 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .grid-row.desktop\:grid-gap-sm {
    margin-left: -1px;
    margin-right: -1px;
  }
  .grid-row.desktop\:grid-gap-sm > * {
    padding-left: 1px;
    padding-right: 1px;
  }
  .grid-row.desktop\:grid-gap-md {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .grid-row.desktop\:grid-gap-md > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .grid-row.desktop\:grid-gap-lg {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .grid-row.desktop\:grid-gap-lg > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}

[class*=grid-col] {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.grid-col {
  flex: 1 1 0%;
  width: auto;
  max-width: 100%;
  min-width: 1px;
}

.grid-col-auto {
  flex: 0 1 auto;
  width: auto;
  max-width: 100%;
}

.grid-col-fill {
  flex: 1 1 0%;
  width: auto;
  max-width: 100%;
  min-width: 1px;
}

.grid-col-1 {
  flex: 0 1 auto;
  width: 8.3333333333%;
}

.grid-col-2 {
  flex: 0 1 auto;
  width: 16.6666666667%;
}

.grid-col-3 {
  flex: 0 1 auto;
  width: 25%;
}

.grid-col-4 {
  flex: 0 1 auto;
  width: 33.3333333333%;
}

.grid-col-5 {
  flex: 0 1 auto;
  width: 41.6666666667%;
}

.grid-col-6 {
  flex: 0 1 auto;
  width: 50%;
}

.grid-col-7 {
  flex: 0 1 auto;
  width: 58.3333333333%;
}

.grid-col-8 {
  flex: 0 1 auto;
  width: 66.6666666667%;
}

.grid-col-9 {
  flex: 0 1 auto;
  width: 75%;
}

.grid-col-10 {
  flex: 0 1 auto;
  width: 83.3333333333%;
}

.grid-col-11 {
  flex: 0 1 auto;
  width: 91.6666666667%;
}

.grid-col-12 {
  flex: 0 1 auto;
  width: 100%;
}

@media all and (min-width: 30em) {
  .mobile-lg\:grid-col {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
  }
  .mobile-lg\:grid-col-fill {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
  }
  .mobile-lg\:grid-col-auto {
    flex: 0 1 auto;
    width: auto;
    max-width: 100%;
  }
  .mobile-lg\:grid-col-1 {
    flex: 0 1 auto;
    width: 8.3333333333%;
  }
  .mobile-lg\:grid-col-2 {
    flex: 0 1 auto;
    width: 16.6666666667%;
  }
  .mobile-lg\:grid-col-3 {
    flex: 0 1 auto;
    width: 25%;
  }
  .mobile-lg\:grid-col-4 {
    flex: 0 1 auto;
    width: 33.3333333333%;
  }
  .mobile-lg\:grid-col-5 {
    flex: 0 1 auto;
    width: 41.6666666667%;
  }
  .mobile-lg\:grid-col-6 {
    flex: 0 1 auto;
    width: 50%;
  }
  .mobile-lg\:grid-col-7 {
    flex: 0 1 auto;
    width: 58.3333333333%;
  }
  .mobile-lg\:grid-col-8 {
    flex: 0 1 auto;
    width: 66.6666666667%;
  }
  .mobile-lg\:grid-col-9 {
    flex: 0 1 auto;
    width: 75%;
  }
  .mobile-lg\:grid-col-10 {
    flex: 0 1 auto;
    width: 83.3333333333%;
  }
  .mobile-lg\:grid-col-11 {
    flex: 0 1 auto;
    width: 91.6666666667%;
  }
  .mobile-lg\:grid-col-12 {
    flex: 0 1 auto;
    width: 100%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-col {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
  }
  .tablet\:grid-col-fill {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
  }
  .tablet\:grid-col-auto {
    flex: 0 1 auto;
    width: auto;
    max-width: 100%;
  }
  .tablet\:grid-col-1 {
    flex: 0 1 auto;
    width: 8.3333333333%;
  }
  .tablet\:grid-col-2 {
    flex: 0 1 auto;
    width: 16.6666666667%;
  }
  .tablet\:grid-col-3 {
    flex: 0 1 auto;
    width: 25%;
  }
  .tablet\:grid-col-4 {
    flex: 0 1 auto;
    width: 33.3333333333%;
  }
  .tablet\:grid-col-5 {
    flex: 0 1 auto;
    width: 41.6666666667%;
  }
  .tablet\:grid-col-6 {
    flex: 0 1 auto;
    width: 50%;
  }
  .tablet\:grid-col-7 {
    flex: 0 1 auto;
    width: 58.3333333333%;
  }
  .tablet\:grid-col-8 {
    flex: 0 1 auto;
    width: 66.6666666667%;
  }
  .tablet\:grid-col-9 {
    flex: 0 1 auto;
    width: 75%;
  }
  .tablet\:grid-col-10 {
    flex: 0 1 auto;
    width: 83.3333333333%;
  }
  .tablet\:grid-col-11 {
    flex: 0 1 auto;
    width: 91.6666666667%;
  }
  .tablet\:grid-col-12 {
    flex: 0 1 auto;
    width: 100%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-col {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
  }
  .desktop\:grid-col-fill {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
  }
  .desktop\:grid-col-auto {
    flex: 0 1 auto;
    width: auto;
    max-width: 100%;
  }
  .desktop\:grid-col-1 {
    flex: 0 1 auto;
    width: 8.3333333333%;
  }
  .desktop\:grid-col-2 {
    flex: 0 1 auto;
    width: 16.6666666667%;
  }
  .desktop\:grid-col-3 {
    flex: 0 1 auto;
    width: 25%;
  }
  .desktop\:grid-col-4 {
    flex: 0 1 auto;
    width: 33.3333333333%;
  }
  .desktop\:grid-col-5 {
    flex: 0 1 auto;
    width: 41.6666666667%;
  }
  .desktop\:grid-col-6 {
    flex: 0 1 auto;
    width: 50%;
  }
  .desktop\:grid-col-7 {
    flex: 0 1 auto;
    width: 58.3333333333%;
  }
  .desktop\:grid-col-8 {
    flex: 0 1 auto;
    width: 66.6666666667%;
  }
  .desktop\:grid-col-9 {
    flex: 0 1 auto;
    width: 75%;
  }
  .desktop\:grid-col-10 {
    flex: 0 1 auto;
    width: 83.3333333333%;
  }
  .desktop\:grid-col-11 {
    flex: 0 1 auto;
    width: 91.6666666667%;
  }
  .desktop\:grid-col-12 {
    flex: 0 1 auto;
    width: 100%;
  }
}
.grid-offset-1 {
  margin-left: 8.3333333333%;
}

.grid-offset-2 {
  margin-left: 16.6666666667%;
}

.grid-offset-3 {
  margin-left: 25%;
}

.grid-offset-4 {
  margin-left: 33.3333333333%;
}

.grid-offset-5 {
  margin-left: 41.6666666667%;
}

.grid-offset-6 {
  margin-left: 50%;
}

.grid-offset-7 {
  margin-left: 58.3333333333%;
}

.grid-offset-8 {
  margin-left: 66.6666666667%;
}

.grid-offset-9 {
  margin-left: 75%;
}

.grid-offset-10 {
  margin-left: 83.3333333333%;
}

.grid-offset-11 {
  margin-left: 91.6666666667%;
}

.grid-offset-12 {
  margin-left: 100%;
}

.grid-offset-none {
  margin-left: 0;
}

@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-1 {
    margin-left: 8.3333333333%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-2 {
    margin-left: 16.6666666667%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-3 {
    margin-left: 25%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-4 {
    margin-left: 33.3333333333%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-5 {
    margin-left: 41.6666666667%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-6 {
    margin-left: 50%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-7 {
    margin-left: 58.3333333333%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-8 {
    margin-left: 66.6666666667%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-9 {
    margin-left: 75%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-10 {
    margin-left: 83.3333333333%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-11 {
    margin-left: 91.6666666667%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-12 {
    margin-left: 100%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-none {
    margin-left: 0;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-1 {
    margin-left: 8.3333333333%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-2 {
    margin-left: 16.6666666667%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-3 {
    margin-left: 25%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-4 {
    margin-left: 33.3333333333%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-5 {
    margin-left: 41.6666666667%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-6 {
    margin-left: 50%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-7 {
    margin-left: 58.3333333333%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-8 {
    margin-left: 66.6666666667%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-9 {
    margin-left: 75%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-10 {
    margin-left: 83.3333333333%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-11 {
    margin-left: 91.6666666667%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-12 {
    margin-left: 100%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-none {
    margin-left: 0;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-1 {
    margin-left: 8.3333333333%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-2 {
    margin-left: 16.6666666667%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-3 {
    margin-left: 25%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-4 {
    margin-left: 33.3333333333%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-5 {
    margin-left: 41.6666666667%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-6 {
    margin-left: 50%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-7 {
    margin-left: 58.3333333333%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-8 {
    margin-left: 66.6666666667%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-9 {
    margin-left: 75%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-10 {
    margin-left: 83.3333333333%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-11 {
    margin-left: 91.6666666667%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-12 {
    margin-left: 100%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-none {
    margin-left: 0;
  }
}
/* stylelint-enable */
.usa-media-block {
  align-items: flex-start;
  display: flex;
}

.usa-media-block__img {
  flex-shrink: 0;
  margin-right: 0.5rem;
}

.usa-media-block__body {
  flex: 1 1 0%;
}

.usa-banner {
  font-family: Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
  font-size: 1.06rem;
  line-height: 1.5;
  background-color: #f0f0f0;
}
@media all and (min-width: 40em) {
  .usa-banner {
    font-size: 0.87rem;
    padding-bottom: 0;
  }
}
.usa-banner .usa-accordion {
  font-family: Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
  font-size: 1.06rem;
  line-height: 1.5;
}
.usa-banner .grid-row {
  display: flex;
  flex-wrap: wrap;
}
.usa-banner .grid-row.grid-gap-lg {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}
.usa-banner .grid-row.grid-gap-lg > * {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
@media all and (min-width: 40em) {
  .usa-banner .grid-row .tablet\:grid-col-6 {
    flex: 0 1 auto;
    width: 50%;
  }
}

.usa-banner__header,
.usa-banner__content {
  color: #1b1b1b;
}

.usa-banner__content {
  margin-left: auto;
  margin-right: auto;
  max-width: 64rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: transparent;
  font-size: 1rem;
  overflow: hidden;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
  padding-top: 0.25rem;
  width: 100%;
}
@media all and (min-width: 64em) {
  .usa-banner__content {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .usa-banner__content {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .usa-banner__content {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
  }
}
.usa-banner__content p:first-child {
  margin: 0;
}

.usa-banner__guidance {
  display: flex;
  align-items: flex-start;
  max-width: 64ex;
  padding-top: 1rem;
}
@media all and (min-width: 40em) {
  .usa-banner__guidance {
    padding-top: 0;
  }
}

.usa-banner__lock-image {
  height: 1.5ex;
  width: 1.21875ex;
}
.usa-banner__lock-image path {
  fill: currentColor;
}
@media (forced-colors: active) {
  .usa-banner__lock-image path {
    fill: CanvasText;
  }
}

.usa-banner__inner {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 64rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-right: 0;
}
@media all and (min-width: 64em) {
  .usa-banner__inner {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .usa-banner__inner {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .usa-banner__inner {
    align-items: center;
  }
}

.usa-banner__header {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  font-size: 0.8rem;
  font-weight: normal;
  min-height: 3rem;
  position: relative;
}
@media all and (min-width: 40em) {
  .usa-banner__header {
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;
    min-height: 0;
  }
}

.usa-banner__header-text {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 0.8rem;
  line-height: 1.1;
}

.usa-banner__header-action {
  color: #005ea2;
  line-height: 1.1;
  margin-bottom: 0;
  margin-top: 2px;
  text-decoration: underline;
}
.usa-banner__header-action::after {
  background-image: url("../img/usa-icons/expand_more.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1rem 1rem;
  display: inline-block;
  height: 1rem;
  width: 1rem;
  content: "";
  vertical-align: middle;
  margin-left: auto;
}
@supports (mask: url("")) {
  .usa-banner__header-action::after {
    background: none;
    background-color: #005ea2;
    mask-image: url("../img/usa-icons/expand_more.svg"), linear-gradient(transparent, transparent);
    mask-position: center center;
    mask-repeat: no-repeat;
    mask-size: 1rem 1rem;
  }
  .usa-banner__header-action::after:hover {
    background-color: #1a4480;
  }
}
.usa-banner__header-action:hover::after {
  content: "";
  background-color: #1a4480;
}
.usa-banner__header-action:visited {
  color: #54278f;
}
.usa-banner__header-action:hover, .usa-banner__header-action:active {
  color: #1a4480;
}
.usa-banner__header--expanded .usa-banner__header-action {
  display: none;
}
@media all and (min-width: 40em) {
  .usa-banner__header-action {
    display: none;
  }
}
@media (forced-colors: active) {
  .usa-banner__header-action {
    color: LinkText;
  }
  .usa-banner__header-action::after {
    background-color: ButtonText;
  }
}

.usa-banner__header-flag {
  float: left;
  margin-right: 0.5rem;
  width: 1rem;
}
@media all and (min-width: 40em) {
  .usa-banner__header-flag {
    margin-right: 0.5rem;
    padding-top: 0;
  }
}

.usa-banner__header--expanded {
  padding-right: 3.5rem;
}
@media all and (min-width: 40em) {
  .usa-banner__header--expanded {
    background-color: transparent;
    display: block;
    font-size: 0.8rem;
    font-weight: normal;
    min-height: 0;
    padding-right: 0;
  }
}
.usa-banner__header--expanded .usa-banner__inner {
  margin-left: 0;
}
@media all and (min-width: 40em) {
  .usa-banner__header--expanded .usa-banner__inner {
    margin-left: auto;
  }
}
.usa-banner__header--expanded .usa-banner__header-action {
  display: none;
}

.usa-banner__button {
  color: #005ea2;
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-weight: normal;
  margin: 0;
  padding: 0;
  text-align: left;
  position: absolute;
  left: 0;
  position: absolute;
  bottom: 0;
  top: 0;
  color: #005ea2;
  text-decoration: underline;
  vertical-align: baseline;
  color: #005ea2;
  display: block;
  font-size: 0.8rem;
  height: auto;
  line-height: 1.1;
  padding-top: 0;
  padding-left: 0;
  text-decoration: none;
  width: auto;
}
.usa-banner__button:visited {
  color: #54278f;
}
.usa-banner__button:hover {
  color: #1a4480;
}
.usa-banner__button:active {
  color: #162e51;
}
.usa-banner__button:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0;
}
.usa-banner__button:hover, .usa-banner__button.usa-button--hover, .usa-banner__button:disabled:hover, .usa-banner__button[aria-disabled=true]:hover, .usa-banner__button:disabled.usa-button--hover, .usa-banner__button[aria-disabled=true].usa-button--hover, .usa-banner__button:active, .usa-banner__button.usa-button--active, .usa-banner__button:disabled:active, .usa-banner__button[aria-disabled=true]:active, .usa-banner__button:disabled.usa-button--active, .usa-banner__button[aria-disabled=true].usa-button--active, .usa-banner__button:disabled:focus, .usa-banner__button[aria-disabled=true]:focus, .usa-banner__button:disabled.usa-focus, .usa-banner__button[aria-disabled=true].usa-focus, .usa-banner__button:disabled, .usa-banner__button[aria-disabled=true], .usa-banner__button.usa-button--disabled {
  background-color: transparent;
  box-shadow: none;
  text-decoration: underline;
}
.usa-banner__button.usa-button--hover {
  color: #1a4480;
}
.usa-banner__button.usa-button--active {
  color: #162e51;
}
.usa-banner__button:disabled, .usa-banner__button[aria-disabled=true], .usa-banner__button:disabled:hover, .usa-banner__button[aria-disabled=true]:hover, .usa-banner__button[aria-disabled=true]:focus {
  color: #757575;
}
@media (forced-colors: active) {
  .usa-banner__button:disabled, .usa-banner__button[aria-disabled=true], .usa-banner__button:disabled:hover, .usa-banner__button[aria-disabled=true]:hover, .usa-banner__button[aria-disabled=true]:focus {
    color: GrayText;
  }
}
.usa-banner__button:visited {
  color: #54278f;
}
.usa-banner__button:hover, .usa-banner__button:active {
  color: #1a4480;
}
@media all and (max-width: 39.99em) {
  .usa-banner__button {
    width: 100%;
  }
  .usa-banner__button:enabled:focus {
    outline-offset: -0.25rem;
  }
}
@media all and (min-width: 40em) {
  .usa-banner__button {
    color: #005ea2;
    position: static;
    bottom: auto;
    left: auto;
    right: auto;
    top: auto;
    display: inline;
    margin-left: 0.5rem;
    position: relative;
  }
  .usa-banner__button::after {
    background-image: url("../img/usa-icons/expand_more.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1rem 1rem;
    display: inline-block;
    height: 1rem;
    width: 1rem;
    content: "";
    vertical-align: middle;
    margin-left: 2px;
  }
  @supports (mask: url("")) {
    .usa-banner__button::after {
      background: none;
      background-color: #005ea2;
      mask-image: url("../img/usa-icons/expand_more.svg"), linear-gradient(transparent, transparent);
      mask-position: center center;
      mask-repeat: no-repeat;
      mask-size: 1rem 1rem;
    }
    .usa-banner__button::after:hover {
      background-color: #1a4480;
    }
  }
  .usa-banner__button:hover::after {
    content: "";
    background-color: #1a4480;
  }
  .usa-banner__button:visited {
    color: #54278f;
  }
  .usa-banner__button:hover, .usa-banner__button:active {
    color: #1a4480;
  }
  .usa-banner__button::after, .usa-banner__button:hover::after {
    position: absolute;
  }
}
@media (min-width: 40em) and (forced-colors: active) {
  .usa-banner__button::after, .usa-banner__button:hover::after {
    background-color: ButtonText;
  }
}
@media all and (min-width: 40em) {
  .usa-banner__button:hover {
    text-decoration: none;
  }
}
.usa-banner__button[aria-expanded=false], .usa-banner__button[aria-expanded=false]:hover, .usa-banner__button[aria-expanded=true], .usa-banner__button[aria-expanded=true]:hover {
  background-image: none;
}
@media (forced-colors: active) {
  .usa-banner__button[aria-expanded=false]::before, .usa-banner__button[aria-expanded=false]:hover::before, .usa-banner__button[aria-expanded=true]::before, .usa-banner__button[aria-expanded=true]:hover::before {
    content: none;
  }
}
@media all and (max-width: 39.99em) {
  .usa-banner__button[aria-expanded=true]::after {
    background-image: url("../img/usa-icons/close.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1.5rem 1.5rem;
    display: inline-block;
    height: 3rem;
    width: 3rem;
    content: "";
    vertical-align: middle;
    margin-left: 0;
  }
  @supports (mask: url("")) {
    .usa-banner__button[aria-expanded=true]::after {
      background: none;
      background-color: #005ea2;
      mask-image: url("../img/usa-icons/close.svg"), linear-gradient(transparent, transparent);
      mask-position: center center;
      mask-repeat: no-repeat;
      mask-size: 1.5rem 1.5rem;
    }
  }
  .usa-banner__button[aria-expanded=true]::before {
    position: absolute;
    bottom: 0;
    top: 0;
    position: absolute;
    right: 0;
    background-color: #dfe1e2;
    content: "";
    display: block;
    height: 3rem;
    width: 3rem;
  }
  .usa-banner__button[aria-expanded=true]::after {
    position: absolute;
    bottom: 0;
    top: 0;
    position: absolute;
    right: 0;
  }
}
@media all and (min-width: 40em) {
  .usa-banner__button[aria-expanded=true] {
    height: auto;
    padding: 0;
    position: relative;
  }
  .usa-banner__button[aria-expanded=true]::after {
    background-image: url("../img/usa-icons/expand_less.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1rem 1rem;
    display: inline-block;
    height: 1rem;
    width: 1rem;
    content: "";
    vertical-align: middle;
    margin-left: 2px;
  }
  @supports (mask: url("")) {
    .usa-banner__button[aria-expanded=true]::after {
      background: none;
      background-color: #005ea2;
      mask-image: url("../img/usa-icons/expand_less.svg"), linear-gradient(transparent, transparent);
      mask-position: center center;
      mask-repeat: no-repeat;
      mask-size: 1rem 1rem;
    }
    .usa-banner__button[aria-expanded=true]::after:hover {
      background-color: #1a4480;
    }
  }
  .usa-banner__button[aria-expanded=true]:hover::after {
    content: "";
    background-color: #1a4480;
  }
  .usa-banner__button[aria-expanded=true]::after, .usa-banner__button[aria-expanded=true]:hover::after {
    position: absolute;
  }
}
@media (min-width: 40em) and (forced-colors: active) {
  .usa-banner__button[aria-expanded=true]::after, .usa-banner__button[aria-expanded=true]:hover::after {
    background-color: ButtonText;
  }
}

.usa-banner__button-text {
  position: absolute;
  left: -999em;
  right: auto;
  text-decoration: underline;
}
@media all and (min-width: 40em) {
  .usa-banner__button-text {
    position: static;
    display: inline;
  }
}
@media (forced-colors: active) {
  .usa-banner__button-text {
    color: LinkText;
  }
}

.usa-banner__icon {
  width: 2.5rem;
}

.usa-js-loading .usa-banner__content {
  position: absolute;
  left: -999em;
  right: auto;
}

/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
USWDS THEME CUSTOM STYLES
----------------------------------------
!! Copy this file to your project's
   sass root. Don't edit the version
   in node_modules.
----------------------------------------
Custom project SASS goes here.

i.e.
@include u-padding-right('05');
----------------------------------------
*/
*, ::after, ::before {
  box-sizing: inherit;
}

.usa-banner {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  font-size: 100%;
  position: relative;
}

button.usa-banner__button {
  font-family: Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
}

@media (min-width: 980px) and (max-width: 1048px) {
  .usa-banner__inner {
    max-width: 60rem;
    padding-left: 0;
  }
}