/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
USWDS THEME CUSTOM STYLES
----------------------------------------
!! Copy this file to your project's
   sass root. Don't edit the version
   in node_modules.
----------------------------------------
Custom project SASS goes here.

i.e.
@include u-padding-right('05');
----------------------------------------
*/
// npx gulp compile
// @use "uswds-core" as *;

*, ::after, ::before {
   box-sizing: inherit;
 }
// html
 .usa-banner{
   line-height: 1.15;
   -webkit-text-size-adjust: 100%;
   box-sizing: border-box;
   font-feature-settings: "kern"1;
   font-kerning: normal;
   // font-family: Source Sans Pro Web,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;
   font-size: 100%;

   position: relative; //hide shadow from medlineplus border
}
button.usa-banner__button{
   font-family: Source Sans Pro Web,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;
}

// Fix to line up with M+ Banners
@media (min-width:980px) and (max-width:1048px) {
   .usa-banner__inner {
      max-width: 60rem;
      padding-left: 0;
   }
}

